import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import jwtDecode from "jwt-decode";

import "./App.scss";

import { ACCOUNT_TYPES } from "./data/constants";

import Login from "./pages/Login/index";
import NotFound from "./pages/NotFound/index";
import Home from "./pages/Home/index";
import RequireAuth from "./components/RequireAuth/index";

import useAuth from "./hooks/useAuth";
import useUser from "./hooks/useUser";
import Sidebar from "./components/Sidebar";
import SingleViewOutgoingNominations from "./pages/Nominations/Outgoing/SingleView";
import SingleViewIncomingNominations from "./pages/Nominations/Incoming/SingleView";
import LearningAgreementList from "./pages/LearningAgreements/Listing";
import withNavBar from "./components/WithNavBar";
import withFooter from "./components/WithFooter";
import Register from "./pages/Register";
import RegisterConfirmation from "./pages/RegisterConfirmation";
import ReusableComponents from "./pages/ReusableComponents";
import PendingRequests from "./pages/PendingRequests";
import ExportPdf from "./pages/Nominations/ExportPdf";
import NominationsList from "./pages/Nominations/Listing";
import SingleViewLA from "./pages/LearningAgreements/View";
import ExportOlaPdf from "./pages/LearningAgreements/View/components/ExportOlaPdf";
import RequirementsNominations from "./pages/Nominations/Requirements";
import AccountsAndAccess from "./pages/AccountsAndAccess";
import OrganizationalUnits from "./pages/OrganizationalUnits";
import Upload from "./pages/LearningAgreements/Upload";
import ShortTermMobilitiesList from "./pages/ShortTermMobilties/Listing";
import SingleViewShortTermMobility from "./pages/ShortTermMobilties/View";
import ExportShortTermPdf from "./pages/ShortTermMobilties/View/components/ExportShortTermPdf";
import withTitle from "./components/withTitle";
import GET_EWP_SETTINGS from "./graphql/queries/settings/getEWPSettings";
import { useQuery, NetworkStatus } from "@apollo/client";
import SingleViewIIAs from "./pages/IIAs/SingleView";
import ViewPdf from "./pages/IIAs/ViewPdf";
import InterInstitutionalAgreementList from "./pages/IIAs/Listing";
import CreateIIA from "./pages/IIAs/Create";
import EditFactsheet from "./pages/IIAs/EditFactsheet";
import EwpSettings from "./pages/EwpSettings";
import ImportIIA from "./pages/IIAs/Import";
import UsefulInfo from "./pages/ErasmusPlusApp/UsefulInfo";
import Event from "./pages/ErasmusPlusApp/Events";
import Deals from "./pages/ErasmusPlusApp/Deals";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import TermsAndConditions from "./pages/TermsAndConditions";
import ExportIIAs from "./pages/IIAs/Export";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import UkraineAssistanceSettings from "./pages/UkraineAssistance";

function App() {
  const { setAuth } = useAuth();
  const { setUser, user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);

      let currentUser = {
        type:
          decodedToken.userType === "HEI"
            ? ACCOUNT_TYPES.hei
            : ACCOUNT_TYPES.eufAdmin,
        heiID: decodedToken.heiID,
        email: decodedToken.email,
      };

      if (currentUser.type === ACCOUNT_TYPES.hei) {
        currentUser = {
          ...currentUser,
          userId: decodedToken.userId,
          erasmusCode: decodedToken.erasmusCode,
          userName: decodedToken.userName,
          permissions: decodedToken.permissions,
        };
      }

      setUser(currentUser);
      setAuth({ accessToken: token });
      navigate(location.pathname, { replace: true });
    } else {
      setAuth({});
      setUser({});
    }
  }, []);

  const {
    loading: loadingEWPSettings,
    error: errorEWPSettings,
    data: dataEWPSettings,
    refetch: refetchQuery,
    networkStatus: networkStatusEWPSettings,
  } = useQuery(GET_EWP_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    skip: !user?.email,
  });

  useEffect(() => {
    refetchQuery();
  }, [navigate]);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="privacy-policy-terms-conditions" element={<TermsAndConditions />} />
      <Route path="register">
        <Route index element={<Register />} />
        <Route
          path="confirm/:registrationId"
          element={<RegisterConfirmation />}
        />
      </Route>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.eufAdmin, ACCOUNT_TYPES.hei]}
          />
        }
      >
        <Route index element={Sidebar(Home)} />
      </Route>
      <Route
        element={<RequireAuth allowedUserTypes={[ACCOUNT_TYPES.eufAdmin]} />}
      >
        <Route path="/requests" element={Sidebar(PendingRequests)} />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["creatingAccounts"]}
          />
        }
      >
        <Route
          path="/accounts-and-access"
          element={Sidebar(AccountsAndAccess)}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["managingOUnits"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_ounits),
            ]}
          />
        }
      >
        <Route
          path="/organizational-units"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <OrganizationalUnits {...props} userHeiID={user.heiID} />
                  ),
                  ""
                ),
                "Organizational Units",
                ""
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
          />
        }
      >
        <Route
          path="/ukraine-assistance"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <UkraineAssistanceSettings {...props} />, ""),
                "Ukraine Assistance",
                ""
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["ewpSettings"]}
          />
        }
      >
        <Route
          path="/ewp/settings"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <EwpSettings {...props} />, ""),
                "EWP SETTINGS",
                ""
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["viewingStudents", "managingStudents"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_ola),
            ]}
          />
        }
      >
        <Route
          path="/ola/outgoing"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <LearningAgreementList
                      {...props}
                      typeOfStudent={1}
                      userHeiID={user.heiID}
                    />
                  ),
                  "Outgoing Online Learning Agreements"
                ),
                "Online Learning Agreement",
                "Outgoing Students"
              )
            )
          )}
        />
        <Route
          path="/ola/incoming"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <LearningAgreementList
                      {...props}
                      typeOfStudent={0}
                      userHeiID={user.heiID}
                    />
                  ),
                  "Incoming Online Learning Agreements"
                ),
                "Online Learning Agreement",
                "Incoming Students"
              )
            )
          )}
        />
        <Route
          path="/short-term/outgoing"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <ShortTermMobilitiesList
                      {...props}
                      typeOfStudent={1}
                      userHeiID={user.heiID}
                    />
                  ),
                  "Outgoing Short Term Mobilities"
                ),
                "Short Term Mobilities",
                "Outgoing Students"
              )
            )
          )}
        />
        <Route
          path="/short-term/incoming"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <ShortTermMobilitiesList
                      {...props}
                      typeOfStudent={0}
                      userHeiID={user.heiID}
                    />
                  ),
                  "Incoming Short Term Mobilities"
                ),
                "Short Term Mobilities",
                "Incoming Students"
              )
            )
          )}
        />
        <Route
          path="/ola/outgoing/:id"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <SingleViewLA {...props} typeOfStudent={"outgoing"} />
                  ),
                  "View single Outgoing Online Learning Agreement"
                ),
                "Online Learning Agreement",
                "Outgoing Students"
              )
            )
          )}
        />
        <Route
          path="/ola/incoming/:id"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <SingleViewLA {...props} typeOfStudent={"incoming"} />
                  ),
                  "View single Incoming Online Learning Agreement"
                ),
                "Online Learning Agreement",
                "Incoming Students"
              )
            )
          )}
        />
        <Route
          path="/short-term/outgoing/:id"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <SingleViewShortTermMobility
                      {...props}
                      typeOfStudent={"outgoing"}
                    />
                  ),
                  "View single Outgoing Short Term Mobility"
                ),
                "Short Term Mobilities",
                "Outgoing Students"
              )
            )
          )}
        />
        <Route
          path="/short-term/incoming/:id"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <SingleViewShortTermMobility
                      {...props}
                      typeOfStudent={"incoming"}
                    />
                  ),
                  "View single Incoming Short Term Mobility"
                ),
                "Short Term Mobilities",
                "Incoming Students"
              )
            )
          )}
        />
        <Route path="/ola/outgoing/export/:uuid" element={<ExportOlaPdf />} />
        <Route path="/ola/incoming/export/:uuid" element={<ExportOlaPdf />} />
        <Route
          path="/short-term/outgoing/export/:uuid"
          element={<ExportShortTermPdf />}
        />
        <Route
          path="/short-term/incoming/export/:uuid"
          element={<ExportShortTermPdf />}
        />
        <Route
          path="/ola/outgoing/export/:uuid/revision/:vid"
          element={<ExportOlaPdf />}
        />
        <Route
          path="/ola/incoming/export/:uuid/revision/:vid"
          element={<ExportOlaPdf />}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["uploadingStudents"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_ola),
            ]}
          />
        }
      >
        <Route
          path="/ola/upload"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  () => <Upload />,
                  "Upload CSV for Online Learning Agreements"
                ),
                "Online Learning Agreement",
                "Upload CSV"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["nominations"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_iia),
            ]}
          />
        }
      >
        <Route
          path="/applications-for-erasmus+/outgoing-students/:id"
          element={Sidebar(
            withTitle(
              SingleViewOutgoingNominations,
              "View single Outgoing Nomination"
            )
          )}
        />
        <Route
          path="/applications-for-erasmus+/incoming-students/:id"
          element={Sidebar(
            withTitle(
              SingleViewIncomingNominations,
              "View single Incoming Nomination"
            )
          )}
        />
        <Route
          path="/applications-for-erasmus+/outgoing-students/export/:id/:uuid"
          element={<ExportPdf />}
        />
        <Route
          path="/applications-for-erasmus+/incoming-students/export/:id/:uuid"
          element={<ExportPdf />}
        />
        <Route
          path="/applications-for-erasmus+/requirements"
          element={Sidebar(RequirementsNominations)}
        />

        <Route
          path="/applications-for-erasmus+/incoming-students"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <NominationsList
                      {...props}
                      userHeiID={user.heiID}
                      userEC={user.erasmusCode}
                    />
                  ),
                  "Incoming Nominations"
                ),
                "Applications for Erasmus+",
                "Incoming Students"
              )
            )
          )}
        />
        <Route
          path="/applications-for-erasmus+/outgoing-students"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <NominationsList
                      {...props}
                      userHeiID={user.heiID}
                      userEC={user.erasmusCode}
                    />
                  ),
                  "Outgoing Nominations"
                ),
                "Applications for Erasmus+",
                "Outgoing Students"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["managingIIAs"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_iia),
            ]}
          />
        }
      >
        <Route path="/iias/single-view/:id" element={Sidebar(SingleViewIIAs)} />
        <Route
          path="/iias/edit/:id"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <CreateIIA {...props} />, "Edit IIA"),
                "Inter-Institutional Agreements",
                "Edit a IIA"
              )
            )
          )}
        />
        <Route path="/iias/export/:id" element={<ViewPdf />} />
        <Route
          path="/iias/export"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <ExportIIAs {...props} />, "Export IIAs"),
                "Inter-Institutional Agreements",
                "Export IIAs"
              )
            )
          )}
        />
        <Route
          path="/iias/iia-list"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => (
                    <InterInstitutionalAgreementList userHeiID={user.heiID} />
                  ),
                  "Inter-Institutional Agreements"
                ),
                "Inter-Institutional Agreements",
                "Listing of Agreements"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["creatingIIA"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_iia),
            ]}
          />
        }
      >
        <Route
          path="/iias/create-iia"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <CreateIIA {...props} />, "Create IIA"),
                "Inter-Institutional Agreements",
                "Create a new IIA"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["importIIA"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_iia),
            ]}
          />
        }
      >
        <Route
          path="/iias/import"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <ImportIIA {...props} />, "Import IIA"),
                "Inter-Institutional Agreements",
                "Import IIA"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["defaultIIAData"]}
            extraConditions={[
              loadingEWPSettings ||
                networkStatusEWPSettings === NetworkStatus.loading ||
                networkStatusEWPSettings === NetworkStatus.refetch ||
                (dataEWPSettings?.ewpConnections?.length > 0 &&
                  dataEWPSettings?.ewpConnections[0]?.api_static),
            ]}
          />
        }
      >
        <Route
          path="/iias/edit-factsheet"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(
                  (props) => <EditFactsheet userHeiID={user.heiID} />,
                  "Inter-Institutional Agreements"
                ),
                "Inter-Institutional Agreements",
                "Edit factsheet"
              )
            )
          )}
        />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedUserTypes={[ACCOUNT_TYPES.hei]}
            allowedPermissions={["erasmus-app"]}
          />
        }
      >
        <Route
          path="/erasmus+-app/useful-info"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <UsefulInfo {...props} />, "Useful Info"),
                "Erasmus+ App",
                "Useful Info"
              )
            )
          )}
        />
        <Route
          path="/erasmus+-app/events"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <Event {...props} />, "Event"),
                "Erasmus+ App",
                "Events"
              )
            )
          )}
        />
        <Route
          path="/erasmus+-app/deals"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle((props) => <Deals {...props} />, "Deals"),
                "Erasmus+ App",
                "Deals"
              )
            )
          )}
        />
      </Route>
      <Route
          path="/unauthorized"
          element={Sidebar(
            withFooter(
              withNavBar(
                withTitle(() => <UnauthorizedPage/>, "Unauthorized"),
                "",
                ""
              )
            )
          )}
        />
      {process.env.NODE_ENV === "development" && (
        <Route path="components" element={<ReusableComponents />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
